/* Author:

*/

// Global Variables

var isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);

// Miscellaneous JS

(function ($) {
    function scrollAnim() {
        ScrollReveal().reveal(
            ".slide-up",
            {
                duration: 900,
                distance: "150px",
                easing: "ease-out",
                origin: "bottom",
                interval: 200,
            },
            150
        );

        ScrollReveal().reveal(
            ".slide-bottom",
            {
                duration: 900,
                distance: "150px",
                easing: "ease-out",
                origin: "top",
                interval: 200,
            },
            150
        );

        ScrollReveal().reveal(
            ".slide-left",
            {
                duration: 900,
                distance: "150px",
                easing: "ease-out",
                origin: "right",
                interval: 200,
            },
            150
        );

        ScrollReveal().reveal(
            ".slide-right",
            {
                duration: 900,
                distance: "150px",
                easing: "ease-out",
                origin: "left",
                interval: 200,
            },
            150
        );
    }

    function menuInit() {
        const toggle = document.querySelector(".menu-toggle");
        const closeToggle = document.querySelector(".close-toggle");
        const body = document.querySelector("body");
        toggle.addEventListener("click", function (e) {
            e.preventDefault();
            body.classList.add("menu-open");
        });
        closeToggle.addEventListener("click", function (e) {
            e.preventDefault();
            body.classList.remove("menu-open");
        });
    }


    function sliderInit() {
        $(".slider-column-3").slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 800,
            draggable: false,
            useTransform: true,
            infinite: false,
            rows: 0,
            centerPadding: "0",
            swipe: false,
            cssEase: "cubic-bezier(1.000, 0.000, 0.000, 1.000)",
            arrows: true,
            dots: false,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                },
            ]
        });
        $(".slider-column-4").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            speed: 800,
            draggable: false,
            useTransform: true,
            infinite: false,
            rows: 0,
            centerPadding: "0",
            swipe: false,
            cssEase: "cubic-bezier(1.000, 0.000, 0.000, 1.000)",
            arrows: true,
            dots: false,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                },
            ]
        });
    }

    function accordionInit() {
        $('.acc').each(function() {
            var $this = $(this);
            var $accToggle = $this.find('.acc__toggle');
            var $accContent = $this.find('.acc__content');
            $accToggle.on('click', function(e) {
                e.preventDefault();
                var $accNextContent = $(this).next();
                $accToggle.removeClass('active');
                $accContent.slideUp();
                if ($accNextContent.is(':hidden')) {
                    $accNextContent.slideDown();
                    $(this).addClass('active');
                    
                } else {
                    $accNextContent.slideUp();
                    $(this).removeClass('active');
                    
                }
            });
        });
        
    }

    function instaFeedInit() {
        // window.addEventListener('load', function () {
        //     var userFeed = new Instafeed ({
        //         get: 'user',
        //         userId: '',
        //         accessToken: '',
        //         limit: 8,
        //         resolution: 'standard_resolution'
        //     });
        //     userFeed.run();
        // });
    }


    function stickyHeader() {

		var distance = 300;
        var $headerContent = $('.header__content');
        var headerHeight = $('.topbar').outerHeight() + $('.navbar').outerHeight();
		var headerOffset = $headerContent.offset().top + headerHeight;
        var headerOffsetShow = headerOffset + distance;
        

		var spacer  = $('<div class="header-spacer" />');
		spacer.css({
			height: headerHeight
        });

		$(window).on('scroll', function() {
			var windowScroll = $(window).scrollTop();

			if (windowScroll > headerOffset) {
				$headerContent.addClass('header-hide');
			}

			if (windowScroll > headerOffsetShow) {
				$('.header').addClass('header--fixed');
				if (spacer) {
                    spacer.insertAfter('.header');
				} 
				$headerContent.removeClass('header-hide').addClass('header-show');
			} else {
				$headerContent.removeClass('header-show');

			}

			if (windowScroll < headerOffset) {
				$headerContent.removeClass('header-hide');
				$('.header').removeClass('header--fixed');
				$('.header-spacer').detach();
			}

        });
        
        
            
        $(window).trigger('scroll');

    }
    

    $(function () {
        FastClick.attach(document.body);

        menuInit();
        sliderInit();
        scrollAnim();

        stickyHeader();
        accordionInit();

    });
})(jQuery);
